import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { ModalModule } from 'src/shared/modal'
import { ProductFormComponent } from './product-form.component'
import { ProductFormService } from './product-form.service'
import { MatFormFieldModule } from '@angular/material/form-field'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { MatInputModule } from '@angular/material/input'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { FlexLayoutModule } from '@angular/flex-layout'


@NgModule({
  declarations: [
    ProductFormComponent,
  ],
  providers: [ProductFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatIconModule,
    ModalModule,
    SelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    FlexLayoutModule,
  ],
})
export class ProductFormModule { }
