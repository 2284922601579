import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { EpochFieldModule } from "src/components/epoch/epoch-field/epoch-field.module";
import { ReactiveAsteriskModule } from "src/components/reactive-asterisk/reactive-asterisk.module";
import { SelectSearchModule } from "src/components/select-search/select-search.module";
import { ModalModule } from "src/shared/modal";
import { ReceiptFormComponent } from "./receipt-form.component";
import { ReceiptFormService } from "./receipt-form.service";

@NgModule({
  declarations: [ReceiptFormComponent],
  providers: [ReceiptFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    EpochFieldModule,
    SelectSearchModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveAsteriskModule,
    ModalModule,
  ],
})
export class ReceiptFormModule {}
