<tc-select-search placeholder="Wrapping" gdColumn="span 3"
    [ctrl]="moForm.controls.wrappingId"
    [items]="wrappings$ | async" bindLabel="name" bindValue="wrapping_id"
    ></tc-select-search>
<tc-select-search placeholder="Weight type" gdColumn="span 3"
    [ctrl]="moForm.controls.weightTypeId"
    [items]="weightTypes$ | async" bindLabel="name" bindValue="type_id"
    ></tc-select-search>
<mat-form-field>
  <mat-label>No of packages</mat-label>
  <input matInput type="number" min="0" [formControl]="moForm.controls.quantity">
</mat-form-field>
<tc-select-search placeholder="Type of packages"
    [ctrl]="moForm.controls.packageId"
    [items]="packageTypes$ | async" bindLabel="name" bindValue="package_id"
    ></tc-select-search>
<mat-form-field>
  <mat-label>Weight of one package</mat-label>
  <input matInput type="number" min="0" [formControl]="moForm.controls.packageSize" [matAutocomplete]="packageSize">
  <mat-autocomplete autoActiveFirstOption #packageSize="matAutocomplete">
    <mat-option *ngFor="let option of weights" [value]="option">{{option}}</mat-option>
  </mat-autocomplete>
</mat-form-field>
<tc-select-search placeholder="Unit of weight"
    [ctrl]="moForm.controls.packageMeasureId"
    [items]="measures$ | async" bindLabel="name" bindValue="measure_id"
    ></tc-select-search>
<mat-form-field>
  <mat-label>Brand</mat-label>
  <input matInput [formControl]="moForm.controls.brand">
</mat-form-field>
<mat-form-field>
  <mat-label>Product code</mat-label>
  <input matInput [formControl]="moForm.controls.productCode">
</mat-form-field>
<mat-form-field gdColumn="span 6">
  <mat-label>Additional specifications</mat-label>
  <textarea matInput rows="10" [formControl]="moForm.controls.additionalSpecs"></textarea>
</mat-form-field>
