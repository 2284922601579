import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ReactiveAsteriskModule } from "src/components/reactive-asterisk/reactive-asterisk.module";
import { SelectSearchModule } from "src/components/select-search/select-search.module";
import { ModalModule } from "src/shared/modal";
import { DocumentFormComponent } from "./document-form.component";
import { DocumentFormService } from "./document-form.service";

@NgModule({
  declarations: [DocumentFormComponent],
  providers: [DocumentFormService],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatTooltipModule,
    ModalModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    FormsModule,
    SelectSearchModule,
  ],
})
export class DocumentFormModule { }
