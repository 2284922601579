import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { ModalModule } from 'src/shared/modal'
import { EpochRangePipe } from '../../filters/epoch-range.pipe'
import { EpochPipe } from '../../filters/epoch.pipe'
import { MeasurePipe } from '../../filters/measure.pipe'
import { SelectSearchModule } from '../select-search/select-search.module'
import { CarrierPickerDialogComponent } from './carrier-picker.component'
import { CarrierPickerService } from './carrier-picker.service'

@NgModule({
  declarations: [CarrierPickerDialogComponent],
  exports: [CarrierPickerDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatProgressBarModule,
    SelectSearchModule,
    ModalModule,
  ],
  providers: [
    CarrierPickerService,
    CurrencyPipe,
    DecimalPipe,
    EpochPipe,
    EpochRangePipe,
    MeasurePipe,
  ],
})
export class CarrierPickerModule {
}
