import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { LocksModule } from 'src/shared/lock/locks.module'
import { ModalModule } from 'src/shared/modal'
import { DealFormPageOverlayComponent } from './deal-form-page-overlay.component'
import { DealFormPageOverlayService } from './deal-form-page-overlay.component.service'
import { DealFormPageModule } from '../deal-form-page.module'

@NgModule({
  declarations: [DealFormPageOverlayComponent],
  providers: [DealFormPageOverlayService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    DealFormPageModule,
    ModalModule,
    MatTooltipModule,
  ],
})
export class DealFormPageOverlayModule { }
