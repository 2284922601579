import { Location, LocationStrategy, PlatformLocation } from '@angular/common'
import { $locationShim, $locationShimProvider, LocationUpgradeModule, UrlCodec } from '@angular/common/upgrade'
import { UpgradeModule } from '@angular/upgrade/static'


/**
 * [Upgrading from AngularJS to Angular](https://angular.io/guide/upgrade)
 * guide doesn't tell you $locationProvider.html5Mode will not be available.
 *
 * This module is cut [from angular tests @ github]
 * (https://github.com/angular/angular/blob/master/packages/common/upgrade/test/upgrade_location_test_module.ts)
 */

export const LocationShimModuleProviders = [
  {
    provide: $locationShim,
    useFactory: provide$location,
    deps: [
      UpgradeModule, Location, PlatformLocation, UrlCodec, LocationStrategy,
    ],
  },
  ...LocationUpgradeModule
      .config()
      .providers,
]

function provide$location(
    ngUpgrade: UpgradeModule, location: Location, platformLocation: PlatformLocation,
    urlCodec: UrlCodec, locationStrategy: LocationStrategy) {
  const $locationProvider =
      new $locationShimProvider(ngUpgrade, location, platformLocation, urlCodec, locationStrategy)

  // NOTE: this entire file is dedicated to run next line once
  $locationProvider.html5Mode(true)

  return $locationProvider.$get()
}
