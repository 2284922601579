import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Message } from '@tradecafe/types/core'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import angular from 'angular'
import { map as _map, orderBy, reject } from 'lodash-es'
import { BehaviorSubject, interval, of } from 'rxjs'
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { MessageApiService } from 'src/api/message'
import { ModalProxyService } from 'src/shared/modal'


const TIME_INTERVAL = 3 * 60 * 1000

@Component({
  selector: 'tc-feeds-bell',
  templateUrl: './feeds-bell.component.html',
  styleUrls: ['./feeds-bell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedsBellComponent extends OnDestroyMixin implements OnInit {
  unreadMessages$ = new BehaviorSubject<Message[]>([])
  unreadMessagesCount$ = this.unreadMessages$.pipe(map(x => x.length), distinctUntilChanged())

  constructor (
    private modalHelper: ModalProxyService,
    private MessageApi: MessageApiService,
    private AuthApi: AuthApiService,
    private router: Router,
  ) {
    super()
  }

  ngOnInit() {
    this.refreshUnreadMessage().pipe(untilComponentDestroyed(this)).subscribe(messages => {
      this.unreadMessages$.next(messages)
    })
  }

  viewAllFeeds() {
    this.router.navigateByUrl('/notification/feeds')
  }

  private refreshUnreadMessage() {
    return this.AuthApi.currentUser$.pipe(
      distinctUntilChanged((a, b) => !!a === !!b),
      switchMap(currentUser => {
        if (!currentUser) return of([])
        return interval(TIME_INTERVAL).pipe(
          switchMap(() => this.MessageApi.getUnread(currentUser.account)),
          map(r => orderBy(r.data, ['created'], ['desc']).map(message => ({
            ...message,
            body_txt: jQuery(`<div>${message.body_txt}</div>`).text(),
          }))))
        }))
  }

  showMessageItem(item: Message) {
    return this.modalHelper.showModal({
      component: 'tcMessageViewForm',
      windowClass: 'modalclone',
      size: 'md',
      backdrop: 'static',
      resolve: {
        message: () => angular.copy(item),
      },
    }).catch(() => {})
    .then(() => {
      const {account} = this.AuthApi.currentUser
      return this.MessageApi.markAsRead(account, [item.message_id]).then(() => {
        this.unreadMessages$.next(reject(this.unreadMessages$.value, { message_id: item.message_id }))
      })
    })
  }

  markAllAsRead() {
    const {account} = this.AuthApi.currentUser
    this.MessageApi.markAsRead(account, _map(this.unreadMessages$.value, 'message_id')).then(() => {
      this.unreadMessages$.next([])
    })
  }

  getMessageId(_index: number, message: Message) {
    return message.message_id
  }
}
