<div class="brand">
  <tc-logo></tc-logo>
  <div class="logo-version" title="v{{version.version}} {{version.hash}} {{env}}" (mousemove)="getReplayId()">
    <span>v{{version.version}}</span>
    <a *ngIf="sentryReplay$ | async as sentryReplay" class="sentry-replay fa fa-brands fa-youtube" [href]="sentryReplay" target="blank"></a>
  </div>
</div>

<section class="tc-nav-icons" fxFlex fxHide fxShow.gt-sm>
  <a *ngIf="canAccess('app.trading')" class="tc-nav-item tc-nav-icon" [matMenuTriggerFor]="tradingMenu"
    routerLinkActive="active">
    <i class="fas fa-globe" aria-hidden="true"></i>
    Trading
    <span *ngIf="newMatchedOffers" class="fas fa-gift info-accent"></span>
  </a>
  <a *ngIf="canAccess('app.logistics')" class="tc-nav-item tc-nav-icon" [matMenuTriggerFor]="logisticsMenu"
    routerLinkActive="active">
    <i class="fas fa-truck" aria-hidden="true"></i>
    Logistics
  </a>
  <a *ngIf="canAccess('app.financial')" class="tc-nav-item tc-nav-icon" [matMenuTriggerFor]="financialMenu"
    routerLinkActive="active">
    <i class="fas fa-money-bill-1" aria-hidden="true"></i>
    Financial
  </a>
  <a *ngIf="canAccess('app.reports')" class="tc-nav-item tc-nav-icon" [matMenuTriggerFor]="chartMenu"
    routerLinkActive="active">
    <i class="fas fa-chart-bar" aria-hidden="true"></i>
    Reports
  </a>
  <a *ngIf="canAccess('app.management')" class="tc-nav-item tc-nav-icon" [matMenuTriggerFor]="managementMenu"
    routerLinkActive="active">
    <i class="fas fa-briefcase" aria-hidden="true"></i>
    Management
  </a>
  <a *ngIf="enableMultipleBids" class="tc-nav-item tc-nav-icon" routerLink="/trading/future-bids" routerLinkActive="active">
    <i class="fas fa-hand-holding-dollar" aria-hidden="true"></i>
    Multiple Bids
  </a>
</section>

<section class="tc-nav-items" fxFlex fxShow fxHide.gt-sm>
  <a class="tc-nav-item tc-nav-icon active" [matMenuTriggerFor]="hamburgerMenu" aria-label="Toggle navigation">
    <i class="fas fa-bars" aria-hidden="true"></i>
  </a>
</section>

<section class="tc-nav-items">
  <tc-feeds-bell></tc-feeds-bell>
  <a class="tc-nav-item">
    Help
  </a>
  <a *ngIf="canAccess('app.setting')" class="tc-nav-item" [matMenuTriggerFor]="settingsMenu">
    Settings <span class="caret"></span>
  </a>
  <a class="tc-nav-item" [matMenuTriggerFor]="authMenu">
    {{username || 'Anonymous'}}<span class="caret"></span>
  </a>
</section>

<mat-menu #settingsMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item routerLink="/setting/consignees" routerLinkActive="active"
      matTooltip="View and update the list of consignees." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Consignees</a>
    <a mat-menu-item routerLink="/setting/companies" routerLinkActive="active"
      matTooltip="View and update the list of companies, departments, and business types." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Companies</a>
    <a mat-menu-item routerLink="/setting/geographic" routerLinkActive="active"
      matTooltip="View and update the list of countries." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Geographic</a>
    <a mat-menu-item routerLink="/setting/locations" routerLinkActive="active"
      matTooltip="View and update the list of locations available for shipping." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Locations</a>
    <a mat-menu-item routerLink="/setting/unlocodes" routerLinkActive="active"
      matTooltip="View the list of unlocodes available for shipping." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Unlocodes</a>
    <a mat-menu-item routerLink="/setting/tracking-providers" routerLinkActive="active"
      matTooltip="View and update the list of tracking providers." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Tracking Providers</a>
    <a mat-menu-item routerLink="/setting/transit-times" routerLinkActive="active"
      matTooltip="View and update the list of known transit times." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Transit Times</a>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/setting/products-services" routerLinkActive="active"
      matTooltip="View and update the list of products, product types, product categories, and product specifications."
      matTooltipClass="nav-tooltip" matTooltipPosition="left">Products & Services</a>
    <a mat-menu-item routerLink="/setting/product-specs" routerLinkActive="active"
      matTooltip="View and update the list of product packaging, measurements, weights, and types."
      matTooltipClass="nav-tooltip" matTooltipPosition="left">Product Specifications</a>
    <mat-divider></mat-divider>
    <a *ngIf="canAccess('app.setting.custom-costs.default')" mat-menu-item routerLink="/setting/custom-costs"
      routerLinkActive="active" matTooltip="View and update the secondary costs automatically assocaited with a deal."
      matTooltipClass="nav-tooltip" matTooltipPosition="left">Custom Costs</a>
    <a *ngIf="canAccess('app.setting.payments.default')" mat-menu-item routerLink="/setting/payments"
      routerLinkActive="active"
      matTooltip="View and update the list of payment specific date like currency, pricing terms, and payment references."
      matTooltipClass="nav-tooltip" matTooltipPosition="left">Payments</a>
    <mat-divider></mat-divider>
    <a *ngIf="canAccess('app.setting.document-library.default')" mat-menu-item routerLink="/setting/document-library"
      routerLinkActive="active" matTooltip="View and update the document library." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Document Library</a>
    <a *ngIf="canAccess('app.setting.triggers.default')" mat-menu-item routerLink="/setting/triggers"
      routerLinkActive="active" matTooltip="View and update the application trigger settings." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Notification Triggers</a>
    <a *ngIf="canAccess('app.setting.templates.default')" mat-menu-item routerLink="/setting/templates"
      routerLinkActive="active" matTooltip="View and update the application template settings."
      matTooltipClass="nav-tooltip" matTooltipPosition="left">Message Templates</a>
    <a *ngIf="canAccess('app.setting.custom-tags.default')" mat-menu-item routerLink="/setting/custom-tags"
      routerLinkActive="active" matTooltip="View and update the application custom tag settings."
      matTooltipClass="nav-tooltip" matTooltipPosition="left">Custom Tags</a>
    <a mat-menu-item routerLink="/setting/status-notes" routerLinkActive="active"
      matTooltip="View and update the application status note settings." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Logistics Notes</a>
  </ng-template>
</mat-menu>

<mat-menu #authMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="changePassword()" matTooltip="Change your password." matTooltipPosition="left"
      matTooltipClass="nav-tooltip">Change password</button>
    <button mat-menu-item (click)="logout()" matTooltip="Log out of the application." matTooltipPosition="left"
      matTooltipClass="nav-tooltip">Logout</button>
  </ng-template>
</mat-menu>

<mat-menu #tradingMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item [routerLink]="routeUrl('app.trading.estimates')" routerLinkActive="active"
      matTooltip="View your list of previously created estimates." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">Estimates</a>
    <a mat-menu-item [routerLink]="routeUrl('app.trading.supplier-offers')" routerLinkActive="active"
      matTooltip="View your list of all supplier offers." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">Supplier Offers</a>
    <a mat-menu-item [routerLink]="routeUrl('app.trading.matched-offers')" routerLinkActive="active"
      matTooltip="View your list of all matched offers." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">Matched Offers
      <span *ngIf="newMatchedOffers" class="info-accent">{{newMatchedOffers}}</span>
    </a>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/trading/deals" routerLinkActive="active"
      matTooltip="View your list of previously created deals." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">Deals List</a>
    <a mat-menu-item routerLink="/trading/deals/new" routerLinkActive="active" matTooltip="Create a new deal."
      matTooltipPosition="right" matTooltipClass="nav-tooltip">New Deal</a>
    <a mat-menu-item [routerLink]="routeUrl('app.trading.fx-rates')" routerLinkActive="active"
      matTooltip="View past and present foreign exchange rates." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">FX Rates</a>
    <a mat-menu-item [routerLink]="routeUrl('app.trading.freight_rates')" routerLinkActive="active"
      matTooltip="View active freight rates available for shipping." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">Freight Rates</a>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/trading/shipping-log" routerLinkActive="active" matTooltip="View your shipping log."
      matTooltipPosition="right" matTooltipClass="nav-tooltip">Shipping Log</a>
    <a mat-menu-item routerLink="/trading/client-shipping-log" routerLinkActive="active"
      matTooltip="Edit your client's shipping log view." matTooltipPosition="right" matTooltipClass="nav-tooltip">Client
      Shipping Log</a>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/trading/vendor-invoices" routerLinkActive="active"
      matTooltip="View your list of vendor invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip">Vendor
      Invoices</a>
    <a mat-menu-item routerLink="/trading/buyer-invoices" routerLinkActive="active"
      matTooltip="View your list of buyer invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip">Buyer
      Invoices</a>
    <a mat-menu-item [routerLink]="routeUrl('app.trading.credit-notes')" routerLinkActive="active"
      matTooltip="View your list of credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip">Credit
      Notes</a>
    <a mat-menu-item [routerLink]="routeUrl('app.trading.vendor-credits')" routerLinkActive="active"
      matTooltip="View your list of vendor credits." matTooltipPosition="right" matTooltipClass="nav-tooltip">Vendor
      Credits</a>
    <a mat-menu-item [routerLink]="routeUrl('app.trading.ar-report')" routerLinkActive="active"
      matTooltip="This report displays information for overdue buyer invoices." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">AR Report</a>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/setting/companies" routerLinkActive="active"
      matTooltip="View and update the list of companies, departments, and business types." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Company Settings</a>
      <a mat-menu-item routerLink="/setting/buyers-groups" routerLinkActive="active"
      matTooltip="View and update the list of buyer grouping." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Buyer Grouping</a>
  </ng-template>
</mat-menu>

<mat-menu #integrationsMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item routerLink="/logistics/integrations/deringer" routerLinkActive="active"
      matTooltip="View deringer integration logs." matTooltipPosition="right" matTooltipClass="nav-tooltip">Deringer</a>
    <a mat-menu-item routerLink="/logistics/integrations/montship" routerLinkActive="active"
      matTooltip="View montship integration logs." matTooltipPosition="right" matTooltipClass="nav-tooltip">Montship</a>
    <a mat-menu-item routerLink="/logistics/integrations/aes" routerLinkActive="active"
      matTooltip="View AES integration logs." matTooltipPosition="right" matTooltipClass="nav-tooltip">AES</a>
    <a mat-menu-item routerLink="/logistics/integrations/macropoint" routerLinkActive="active"
      matTooltip="View Macropoint integration logs." matTooltipPosition="right" matTooltipClass="nav-tooltip">Macropoint</a>
  </ng-template>
</mat-menu>

<mat-menu #logisticsMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item routerLink="/logistics/shipping-log" routerLinkActive="active"
      matTooltip="View your list of shipments associated with active deals." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">Shipping Log</a>
    <a mat-menu-item routerLink="/logistics/indexing-report" routerLinkActive="active"
      matTooltip="View your list of corrupt deals" matTooltipPosition="right" matTooltipClass="nav-tooltip">Indexing
      Report</a>
    <a mat-menu-item routerLink="/logistics/vendor-invoices" routerLinkActive="active"
      matTooltip="View vendor's invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip">Vendor Invoices</a>
    <a mat-menu-item routerLink="/logistics/buyer-invoices" routerLinkActive="active"
      matTooltip="View buyer's invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip">Buyer Invoices</a>
    <a mat-menu-item [routerLink]="routeUrl('app.logistics.credit-notes')" routerLinkActive="active"
      matTooltip="View credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip">Credit Notes</a>
    <a mat-menu-item [routerLink]="routeUrl('app.logistics.vendor-credits')" routerLinkActive="active"
      matTooltip="View vendor credits." matTooltipPosition="right" matTooltipClass="nav-tooltip">Vendor Credits</a>
    <a mat-menu-item routerLink="/logistics/integrations/deringer" routerLinkActive="active"
      [matMenuTriggerFor]="integrationsMenu">Integrations</a>
    <a mat-menu-item [routerLink]="routeUrl('app.logistics.transportations')" routerLinkActive="active"
      matTooltip="View your list of shipping segments associated with active shipments." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">Transportation Summary</a>
    <a mat-menu-item [routerLink]="routeUrl('app.logistics.freight_rates')" routerLinkActive="active"
      matTooltip="View active freight rates available for shipping." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">Freight Rates</a>
    <a mat-menu-item routerLink="/logistics/upload-history" routerLinkActive="active"
      matTooltip="View upload history." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">Upload History</a>
    <a mat-menu-item [routerLink]="routeUrl('app.logistics.client_shipping_log')" routerLinkActive="active"
      matTooltip="Edit your client's shipping log view." matTooltipPosition="right" matTooltipClass="nav-tooltip">Client
      Shipping Log</a>
    <a mat-menu-item [routerLink]="routeUrl('app.logistics.documents')" routerLinkActive="active"
      matTooltip="View documents." matTooltipPosition="right" matTooltipClass="nav-tooltip">Documents</a>
    <a mat-menu-item [routerLink]="routeUrl('app.logistics.ar-report')" routerLinkActive="active"
      matTooltip="This report displays information for overdue buyer invoices." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">AR Report</a>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/setting/companies" routerLinkActive="active"
      matTooltip="View and update the list of companies, departments, and business types." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Company Settings</a>
    <a mat-menu-item routerLink="/setting/products-services" routerLinkActive="active"
      matTooltip="View and update the list of products, product types, product categories, and product specifications."
      matTooltipClass="nav-tooltip" matTooltipPosition="left">Products & Services</a>
    <a mat-menu-item routerLink="/setting/locations" routerLinkActive="active"
      matTooltip="View and update the list of locations available for shipping." matTooltipClass="nav-tooltip"
      matTooltipPosition="left">Locations</a>
  </ng-template>
</mat-menu>

<mat-menu #financialMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item [routerLink]="routeUrl('app.financial.receipts')" routerLinkActive="active"
      matTooltip="View and upload receipts received from clients." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">Receipts</a>
    <a mat-menu-item [routerLink]="routeUrl('app.financial.fx-rates')" routerLinkActive="active"
      matTooltip="View past and present foreign exchange rates." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">FX Rates</a>
    <a mat-menu-item routerLink="/financial/payables" routerLinkActive="active" matTooltip="View client's payables."
      matTooltipPosition="right" matTooltipClass="nav-tooltip">Payables</a>
    <a mat-menu-item [routerLink]="routeUrl('app.financial.vendor-credits')" routerLinkActive="active"
      matTooltip="View your list of vendor credits." matTooltipPosition="right" matTooltipClass="nav-tooltip">Vendor
      Credits</a>
    <a mat-menu-item routerLink="/financial/buyer-invoices" routerLinkActive="active"
      matTooltip="View buyer's payables." matTooltipPosition="right" matTooltipClass="nav-tooltip">Buyer Invoices</a>
    <a mat-menu-item [routerLink]="routeUrl('app.financial.credit-notes')" routerLinkActive="active"
      matTooltip="View credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip">Credit Notes</a>
    <a mat-menu-item [routerLink]="routeUrl('app.financial.ar-report')" routerLinkActive="active"
      matTooltip="This report displays information for overdue buyer invoices." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">AR Report</a>
  </ng-template>
</mat-menu>

<mat-menu #managementMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item [routerLink]="routeUrl('app.management.audit-trails')" routerLinkActive="active"
      matTooltip="View audit trails." matTooltipPosition="right" matTooltipClass="nav-tooltip">Audit</a>
    <a mat-menu-item [routerLink]="routeUrl('app.management.credit-notes')" routerLinkActive="active"
      matTooltip="View credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip">Credit Notes</a>
    <a mat-menu-item [routerLink]="routeUrl('app.management.credit-overrides')" routerLinkActive="active"
      matTooltip="View credit overrides." matTooltipPosition="right" matTooltipClass="nav-tooltip">Credit Overrides</a>
    <a mat-menu-item routerLink="/management/vendor-invoices" routerLinkActive="active"
      matTooltip="View vendor's invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip">Vendor Invoices</a>
    <a mat-menu-item routerLink="/management/buyer-invoices" routerLinkActive="active"
      matTooltip="View buyer's invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip">Buyer Invoices</a>
    <a mat-menu-item [routerLink]="routeUrl('app.management.ar-report')" routerLinkActive="active"
      matTooltip="This report displays information for overdue buyer invoices." matTooltipPosition="right"
      matTooltipClass="nav-tooltip">AR Report</a>
    <a mat-menu-item routerLink="/management/negative-deals" routerLinkActive="active"
      matTooltip="This shows negative deals." matTooltipPosition="right" matTooltipClass="nav-tooltip">Negative
      Deals</a>
  </ng-template>
</mat-menu>

<mat-menu #hamburgerMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a *ngIf="canAccess('app.trading')" [routerLink]="routeUrl('app.trading')" mat-menu-item
      [matMenuTriggerFor]="tradingMenu" routerLinkActive="active">
      <i class="fas fa-globe" aria-hidden="true"></i>
      Trading
      <span *ngIf="newMatchedOffers" class="fas fa-gift info-accent"></span>
    </a>
    <a *ngIf="canAccess('app.logistics')" [routerLink]="routeUrl('app.logistics')" mat-menu-item
      [matMenuTriggerFor]="logisticsMenu" routerLinkActive="active">
      <i class="fas fa-truck" aria-hidden="true"></i>
      Logistics
    </a>
    <a *ngIf="canAccess('app.financial')" [routerLink]="routeUrl('app.financial')" mat-menu-item
      [matMenuTriggerFor]="financialMenu" routerLinkActive="active">
      <i class="fas fa-money-bill-1" aria-hidden="true"></i>
      Financial
    </a>
    <a *ngIf="canAccess('app.reports')" [routerLink]="routeUrl('app.reports')" mat-menu-item
      [matMenuTriggerFor]="chartMenu" routerLinkActive="active">
      <i class="fas fa-chart-bar" aria-hidden="true"></i>
      Reports
    </a>
    <a *ngIf="canAccess('app.management')" [routerLink]="routeUrl('app.management')" mat-menu-item
      [matMenuTriggerFor]="managementMenu" routerLinkActive="active">
      <i class="fas fa-briefcase" aria-hidden="true"></i>
      Management
    </a>
    <a *ngIf="canAccess('app.trading.future-bids') && enableMultipleBids" [routerLink]="routeUrl('app.trading.future-bids')" mat-menu-item
      routerLinkActive="active">
      <i class="fas fa-hand-holding-dollar" aria-hidden="true"></i>
      Multiple Bids
    </a>
  </ng-template>
</mat-menu>

<mat-menu #chartMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item
    (click)="goToLink('https://tableau.tradecafe.com')"
    routerLinkActive="active"
    matTooltip="Reports"
    matTooltipPosition="right"
    matTooltipClass="nav-tooltip"
    routerLinkActive="active">
    Reports
  </a>
    <a *ngIf="enableChartFeature" mat-menu-item routerLink="/reports/chart"
    routerLinkActive="active"
    matTooltip="Internal Pricing Chart"
    matTooltipPosition="right"
    matTooltipClass="nav-tooltip">Chart</a>
   </ng-template>
</mat-menu>
