import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ModalModule } from "src/shared/modal";
import { PipesModule } from "src/shared/pipes/pipes.module";
import { SelectSearchModule } from "../select-search/select-search.module";
import { DropzoneComponent } from "./dropzone.component";
import { UploaderDialogComponent } from "./uploader-dialog.component";
import { UploaderDialogService } from "./uploader-dialog.service";

@NgModule({
  declarations: [UploaderDialogComponent, DropzoneComponent],
  providers: [UploaderDialogService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    ModalModule,
    SelectSearchModule,
    PipesModule,
  ],
})
export class UploaderDialogModule {}
