import { Injectable } from "@angular/core";
import { RoutesApiService } from "src/api/shipment-routing/routes";

import { SegmentType, ShipmentRateCommodity } from "@tradecafe/types/core";

export interface PathObject {
  path: RouteNode[],
  length: number, // This is the number of connections/rates in the path (_not_ the length of the path array)
  total_cost: number,
  total_time: number,
}

export class RouteNode {
  labels: string[];
}

export interface RateNode extends RouteNode {
  rate_id: string;
  type: SegmentType;
  until: number;
  commodity: ShipmentRateCommodity;
  weight_max: number;
  weight_metric: string;
  carrier_id: string;
  origin_id: string;
  destination_id: string;
  container_size: string;
  rate_amount: number;
  rate_currency: string;
  transit_time: number;
  description?: string;

  rate_amount_CAD?: number;
  weight_max_lbs?: number;
  lastNormalized?: number;
  type_enum?: number;
}

export interface LocationNode extends RouteNode {
  location_id: string;
  name: string;
  city: string;
  state: string;
  country: string;
  description?: string;
  unlocode?: string;
  port?: string;
  schedule_d_port_code?: string;
  schedule_k_port_code?: string;
}

export class PathResult {
  paths: PathObject[];
}

export class PathOptions {
  count?: number;
  metric?: string
  filters?: RouteFilter
}

export class RouteFilter {
  timestamp?: number;
  min_weight_lbs?: number;
  types?: SegmentType[];
}

export const isLocationNode = (node: RouteNode): node is LocationNode => node.labels?.includes('Location');
export const isRateNode = (node: RouteNode): node is RateNode => node.labels?.includes('Rate');

@Injectable()
export class RoutesService {
  constructor(
    private RoutesApi: RoutesApiService,
  ) {}

  async getPaths(origin_id: string, destination_id: string, count?: number, filters?: RouteFilter) {
    const { data } = await this.RoutesApi.getPaths({ origin_id, destination_id, options: { count, filters } });
    return data;
  }

  async getShortestPaths(origin_id: string, destination_id: string, count?: number, filters?: RouteFilter): Promise<PathResult> {
    const { data } = await this.RoutesApi.getShortestPaths({ origin_id, destination_id, options: { count, filters }});
    return data;
  }

  async getLowestCostPaths(origin_id: string, destination_id: string, count?: number, filters?: RouteFilter) {
    const { data } = await this.RoutesApi.getLowestCostPaths({ origin_id, destination_id, options: { count, filters }});
    return data;
  }

  async getFastestPaths(origin_id: string, destination_id: string, count?: number, filters?: RouteFilter) {
    const { data } = await this.RoutesApi.getFastestPaths({ origin_id, destination_id, options: { count, filters }});
    return data;
  }
}
