import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatMenuModule } from '@angular/material/menu'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { FeedsBellComponent } from './feeds-bell.component'


@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    MatMenuModule,
  ],
  declarations: [FeedsBellComponent],
  exports: [FeedsBellComponent],
})
export class FeedsBellModule { }
