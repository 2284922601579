import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ReactiveAsteriskModule } from "src/components/reactive-asterisk/reactive-asterisk.module";
import { ModalModule } from "src/shared/modal";
import { PasswordFormComponent } from "./password-form.component";
import { PasswordFormService } from "./password-form.service";

@NgModule({
  declarations: [PasswordFormComponent],
  providers: [PasswordFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveAsteriskModule,
    ModalModule,
  ],
})
export class PasswordFormModule {}
