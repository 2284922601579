import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { ImportApiService } from 'src/api/import'
import { ElasticSearchService } from '../elastic-search'

@Injectable()
export class ImportLogsElasticSearchService extends ElasticSearchService {

  constructor(
    private ImportApi: ImportApiService,
  ) {
    super()
  }

  filters(query?: any) {
    return this.ImportApi.esSearchFilters({ query }).pipe(
      map(filtersData => this.getViewFilters(filtersData)),
    )
  }

  search(payload) {
    return this.ImportApi.esSearch(payload).pipe(
      map(({ total, hits }) => ({ total, data: hits })),
    )
  }
}
