import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalModule } from 'src/shared/modal';
import { ToasterModule } from 'src/shared/toaster/toaster.module';
import { CostsListModule } from '../costs-list/costs-list.module';
import { CostsListDialogComponent } from './costs-list-dialog.component';
import { CostsListDialogService } from './costs-list-dialog.service';

@NgModule({
  declarations: [CostsListDialogComponent],
  providers: [CostsListDialogService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    CostsListModule,
    ModalModule,
    ToasterModule,
  ]
})
export class CostsListDialogModule { }
