import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { EpochFieldModule } from 'src/components/epoch/epoch-field/epoch-field.module';
import { InlineEditorModule } from 'src/components/inline-editor/inline-editor.module';
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module';
import { TypeSafeMatCellDefModule } from 'src/components/type-safe-mat-cell-def/type-safe-mat-cell-def.module';
import { ModalModule } from 'src/shared/modal';
import { PipesModule } from 'src/shared/pipes/pipes.module';
import { SupplierOfferCloneFormComponent } from './supplier-offer-clone-form.component';
import { SupplierOfferCloneFormService } from './supplier-offer-clone-form.service';

@NgModule({
  declarations: [SupplierOfferCloneFormComponent],
  providers: [SupplierOfferCloneFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatTableModule,
    ModalModule,
    ReactiveFormsModule,
    ReactiveAsteriskModule,
    EpochFieldModule,
    InlineEditorModule,
    PipesModule,
    ScrollingModule,
    TableVirtualScrollModule,
    TypeSafeMatCellDefModule,
  ],
})
export class SupplierOfferCloneFormModule { }
