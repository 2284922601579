import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { SelectSearchModule } from "src/components/select-search/select-search.module";
import { ModalModule } from "src/shared/modal";
import { TransitTimesService } from "../transit-times.service";
import { TransitTimeFormComponent } from "./transit-time-form.component";
import { TransitTimeFormService } from "./transit-time-form.service";

@NgModule({
  declarations: [TransitTimeFormComponent],
  providers: [TransitTimesService, TransitTimeFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    FlexLayoutModule,
    ModalModule,
    SelectSearchModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class TransitTimeFormModule {}
