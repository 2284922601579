import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CostsListModule } from "src/components/costs-list/costs-list.module";
import { ReactiveAsteriskModule } from "src/components/reactive-asterisk/reactive-asterisk.module";
import { SegmentsListModule } from "src/components/segments-list/segments-list.module";
import { ModalModule } from "src/shared/modal";
import { PipesModule } from "src/shared/pipes/pipes.module";
import { BadgeInvalidComponent } from "../../trading/deal-form/badge-invalid/badge-invalid-2.component";
import { AuditLogModule } from "../../../../components/audit-log/audit-log.module";
import { MatchedOfferDetailsComponent } from "./matched-offer-details/matched-offer-details.component";
import { MatchedOfferFormService } from "./matched-offer-form.service";
import { MatchedOfferOverlayComponent } from "./matched-offer-overlay.component";
import { MatchedOfferOverlayService } from "./matched-offer-overlay.service";
import { MatchedOfferSpecsComponent } from "./matched-offer-specs/matched-offer-specs.component";
import { PathsListModule } from "src/components/paths-list/paths-list.module";
import { MatRadioModule } from "@angular/material/radio";

@NgModule({
  declarations: [MatchedOfferOverlayComponent],
  providers: [MatchedOfferOverlayService, MatchedOfferFormService],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    PipesModule,
    MatTabsModule,
    ModalModule,
    MatchedOfferDetailsComponent,
    MatchedOfferSpecsComponent,
    CostsListModule,
    PathsListModule,
    SegmentsListModule,
    AuditLogModule,
    BadgeInvalidComponent,
    MatRadioModule
  ],
})
export class MatchedOfferOverlayModule { }
