import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DealsListModule } from 'src/components/deals-list';
import { SelectSearchModule } from 'src/components/select-search/select-search.module';
import { TableUtilsModule } from 'src/services/table-utils';
import { ModalModule } from 'src/shared/modal';

import { InventoryDealsFormComponent } from './inventory-deals-form.component';
import { InventoryDealsFormService } from './inventory-deals-form.service';
import { DealFormPageOverlayModule } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form-page-overlay/deal-form-page-overlay.component.module';


@NgModule({
  declarations: [InventoryDealsFormComponent],
  providers: [InventoryDealsFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDividerModule,
    MatInputModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    SelectSearchModule,
    DealsListModule,
    DealFormPageOverlayModule,
    ModalModule,
    FlexLayoutModule,
    TableUtilsModule,
  ],
})
export class InventoryDealsFormModule {}
