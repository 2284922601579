import { ChangeDetectionStrategy, Component } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { UserApiService } from 'src/api/user'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { replayForm } from 'src/shared/utils/replay-form'

@Component({
  selector: 'tc-set-password-form',
  templateUrl: './password-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFormComponent {
  constructor(
    private readonly toaster: ToasterService,
    private readonly AuthApi: AuthApiService,
    private readonly UserApi: UserApiService,
    private readonly matDialogRef: MatDialogRef<PasswordFormComponent, void>,
  ) {}


  protected readonly passwordForm = new FormGroup({
    password: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(10),
      this.validateOneUpper(),
      this.validateOneLower(),
      this.validateOneNumber(),
      this.validateOneSpecial(),
    ]),
    confirmPassword: new FormControl<string>('', [Validators.required, this.validateSamePassword()])
  })

  // state
  protected readonly inProgress$ = new BehaviorSubject<boolean>(false)
  protected readonly hasEnoughLength$ = replayForm(this.passwordForm).pipe(map(() =>
    this.passwordForm.controls.password.errors?.['minlength'] || this.passwordForm.controls.password.errors?.['required']? '🚫' : '✅'))
  protected readonly hasOneUpper$ = replayForm(this.passwordForm).pipe(map(() =>
    this.passwordForm.controls.password.errors?.['oneUpper'] ? '🚫' : '✅'))
  protected readonly hasOneLower$ = replayForm(this.passwordForm).pipe(map(() =>
    this.passwordForm.controls.password.errors?.['oneLower'] ? '🚫' : '✅'))
  protected readonly hasOneNumber$ = replayForm(this.passwordForm).pipe(map(() =>
    this.passwordForm.controls.password.errors?.['oneNumber'] ? '🚫' : '✅'))
  protected readonly hasOneSpecial$ = replayForm(this.passwordForm).pipe(map(() =>
    this.passwordForm.controls.password.errors?.['oneSpecial'] ? '🚫' : '✅'))

  protected async save() {
    if (this.inProgress$.value) return
    this.passwordForm.markAllAsTouched()
    if (!this.passwordForm.valid) return

    const { password } = this.passwordForm.value

    try {
      this.inProgress$.next(true)
      await this.UserApi.setPasswordById(this.AuthApi.currentUser.user_id, password)
      this.toaster.success('Password updated successfully.')
      this.matDialogRef.close()
    } catch (err) {
      console.error('Unable to update password.', err)
      this.toaster.error('Unable to update password.', err)
      this.inProgress$.next(false)
    }
  }

  private validateOneUpper(): ValidatorFn {
    return (control: FormControl) => /[A-Z]/.test(control.value) ? null : { oneUpper: true }
  }
  private validateOneLower(): ValidatorFn {
    return (control: FormControl) => /[a-z]/.test(control.value) ? null : { oneLower: true }
  }
  private validateOneNumber(): ValidatorFn {
    return (control: FormControl) => /[0-9]/.test(control.value) ? null : { oneNumber: true }
  }
  private validateOneSpecial(): ValidatorFn {
    return (control: FormControl) => /[^\sa-zA-Z0-9]/.test(control.value) ? null : { oneSpecial: true }
  }
  private validateSamePassword(): ValidatorFn {
    return (control: FormControl ) => {
      const confirmPassword = control.value
      const password = this.passwordForm?.controls.password.value
      return confirmPassword === password ? null : { samePassword: true }
    }
  }

  protected checkForErrorsIn(control: AbstractControl, name: string): string {
    if (control.hasError('required')) return `${name} is required`
    if (control.hasError('minlength')) return `${name} should be at least ${control.errors.minlength.requiredLength} characters long`
    if (control.hasError('oneUpper')) return `${name} should have at least one uppercase letter`
    if (control.hasError('oneLower')) return `${name} should have at least one lowercase letter`
    if (control.hasError('oneNumber')) return `${name} should have at least one number`
    if (control.hasError('oneSpecial')) return `${name} should have at least one special character`
    if (control.hasError('samePassword')) return `Both passwords should be the same`
    return ''
  }

}
