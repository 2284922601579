import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DocumentTagsListModule } from '../document-tags-list/document-tags-list.module';
import { DocumentTagsOverlayComponent } from './document-tags-overlay.component';
import { DocumentTagsOverlayService } from './document-tags-overlay.service';



@NgModule({
  declarations: [
    DocumentTagsOverlayComponent
  ],
  providers: [DocumentTagsOverlayService],
  imports: [
    CommonModule,
    MatDialogModule,
    DocumentTagsListModule,
  ]
})
export class DocumentTagsOverlayModule { }
