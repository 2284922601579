import { ActionReducerMap, MetaReducer } from '@ngrx/store'
import { AccountsState, accountsFeatureKey, accountsReducer } from '../accounts'
import { BanksState, banksFeatureKey, banksReducer } from '../banks'
import { BusinessTypesState, businessTypesFeatureKey, businessTypesReducer } from '../business-types'
import { CarriersState, carriersFeatureKey, carriersReducer } from '../carriers'
import { ConsigneesState, consigneesFeatureKey, consigneesReducer } from '../consignees'
import { CountriesState, countriesFeatureKey, countriesReducer } from '../countries'
import { CurrenciesState, currenciesFeatureKey, currenciesReducer } from '../currencies'
import { DealViewsState, dealViewReducer, dealViewsFeatureKey } from '../deal-view.reducer'
import { DepartmentsState, departmentsFeatureKey, departmentsReducer } from '../departments'
import { DocumentOverridesState, documentOverridesFeatureKey, documentOverridesReducer } from '../document-overrides'
import { DocumentSetsState, documentSetsFeatureKey, documentSetsReducer } from '../document-sets'
import { DocumentTemplatesState, documentTemplatesFeatureKey, documentTemplatesReducer } from '../document-templates'
import { InvoiceViewsState, invoiceViewReducer, invoiceViewsFeatureKey } from '../invoice-view/invoice-view.reducer'
import { ItemTypesState, itemTypesFeatureKey, itemTypesReducer } from '../item-types'
import { LocationsState, locationsFeatureKey, locationsReducer } from '../locations'
import { MeasuresState, measuresFeatureKey, measuresReducer } from '../measures'
import { PackageTypesState, packageTypesFeatureKey, packageTypesReducer } from '../package-types'
import { PaymentMethodsState, paymentMethodsFeatureKey, paymentMethodsReducer } from '../payment-methonds'
import { PaymentReferencesState, paymentReferencesFeatureKey, paymentReferencesReducer } from '../payment-references'
import { PricingTermsState, pricingTermsFeatureKey, pricingTermsReducer } from '../pricing-terms'
import { ProductCategoriesState, productCategoriesFeatureKey, productCategoriesReducer } from '../product-categories'
import { ProductSpecificationsState, productSpecificationsFeatureKey, productSpecificationsReducer } from '../product-specifications'
import { ProductTypesState, productTypesFeatureKey, productTypesReducer } from '../product-types'
import { ProductsState, productsFeatureKey, productsReducer } from '../products'
import { ShipmentRatesState, shipmentRatesFeatureKey, shipmentRatesReducer } from '../shipment-rates'
import { UnlocodesState, unlocodeFeaturesKey, unlocodesReducer } from '../unlocodes'
import { UsersState, usersFeatureKey, usersReducer } from '../users'
import { WeightTypesState, weightTypesFeatureKey, weightTypesReducer } from '../weight-types'
import { WrappingTypesState, wrappingTypesFeatureKey, wrappingTypesReducer } from '../wrapping-types'


export interface State {
  [accountsFeatureKey]: AccountsState
  [banksFeatureKey]: BanksState
  [businessTypesFeatureKey]: BusinessTypesState
  [carriersFeatureKey]: CarriersState
  [consigneesFeatureKey]: ConsigneesState
  [countriesFeatureKey]: CountriesState
  [currenciesFeatureKey]: CurrenciesState
  [dealViewsFeatureKey]: DealViewsState
  [departmentsFeatureKey]: DepartmentsState
  [documentOverridesFeatureKey]: DocumentOverridesState
  [documentSetsFeatureKey]: DocumentSetsState
  [documentTemplatesFeatureKey]: DocumentTemplatesState
  [invoiceViewsFeatureKey]: InvoiceViewsState
  [itemTypesFeatureKey]: ItemTypesState
  [locationsFeatureKey]: LocationsState
  [measuresFeatureKey]: MeasuresState
  [measuresFeatureKey]: MeasuresState
  [packageTypesFeatureKey]: PackageTypesState
  [paymentMethodsFeatureKey]: PaymentMethodsState
  [paymentReferencesFeatureKey]: PaymentReferencesState
  [pricingTermsFeatureKey]: PricingTermsState
  [productCategoriesFeatureKey]: ProductCategoriesState
  [productsFeatureKey]: ProductsState
  [productSpecificationsFeatureKey]: ProductSpecificationsState
  [productTypesFeatureKey]: ProductTypesState
  [shipmentRatesFeatureKey]: ShipmentRatesState
  [usersFeatureKey]: UsersState
  [unlocodeFeaturesKey]: UnlocodesState
  [weightTypesFeatureKey]: WeightTypesState
  [wrappingTypesFeatureKey]: WrappingTypesState
}

export const reducers: ActionReducerMap<State> = {
  [accountsFeatureKey]: accountsReducer,
  [banksFeatureKey]: banksReducer,
  [businessTypesFeatureKey]: businessTypesReducer,
  [carriersFeatureKey]: carriersReducer,
  [consigneesFeatureKey]: consigneesReducer,
  [countriesFeatureKey]: countriesReducer,
  [currenciesFeatureKey]: currenciesReducer,
  [dealViewsFeatureKey]: dealViewReducer,
  [departmentsFeatureKey]: departmentsReducer,
  [documentOverridesFeatureKey]: documentOverridesReducer,
  [documentSetsFeatureKey]: documentSetsReducer,
  [documentTemplatesFeatureKey]: documentTemplatesReducer,
  [invoiceViewsFeatureKey]: invoiceViewReducer,
  [itemTypesFeatureKey]: itemTypesReducer,
  [locationsFeatureKey]: locationsReducer,
  [measuresFeatureKey]: measuresReducer,
  [packageTypesFeatureKey]: packageTypesReducer,
  [paymentMethodsFeatureKey]: paymentMethodsReducer,
  [paymentReferencesFeatureKey]: paymentReferencesReducer,
  [pricingTermsFeatureKey]: pricingTermsReducer,
  [productCategoriesFeatureKey]: productCategoriesReducer,
  [productsFeatureKey]: productsReducer,
  [productSpecificationsFeatureKey]: productSpecificationsReducer,
  [productTypesFeatureKey]: productTypesReducer,
  [shipmentRatesFeatureKey]: shipmentRatesReducer,
  [usersFeatureKey]: usersReducer,
  [unlocodeFeaturesKey]: unlocodesReducer,
  [weightTypesFeatureKey]: weightTypesReducer,
  [wrappingTypesFeatureKey]: wrappingTypesReducer,
}


export const metaReducers: MetaReducer<State>[] = []
