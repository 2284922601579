import { Component, ElementRef } from '@angular/core'
import { LoadingBarService } from '@ngx-loading-bar/core'
import { distinctUntilChanged, map } from 'rxjs/operators'

@Component({
  selector: 'tc-logo',
  templateUrl: 'logo.component.svg',
  styleUrls: ['logo.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  constructor(loader: LoadingBarService, elRef: ElementRef) {
    // TODO: use untilComponentDestroyed
    loader.value$.pipe(
      map(value => !!value),
      distinctUntilChanged(),
    ).subscribe(value => {
      // NOTE: can't remove class using angular bindings :(
      const el = elRef.nativeElement as HTMLElement
      if (value) el.classList.add('loading')
      else el.classList.remove('loading')
    })
  }
}
