import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { ModalModule } from 'src/shared/modal'
import { CompanyStatusFormComponent } from './company-status-form.component'
import { CompanyStatusFormService } from './company-status-form.service'

@NgModule({
  declarations: [CompanyStatusFormComponent],
  providers: [CompanyStatusFormService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatProgressBarModule,
    ModalModule,
    SelectSearchModule,
  ],
})
export class CompanyStatusFormModule { }
