<div class="dropdown" uib-dropdown>
  <a role="button" class="badge-holder toggle" [matMenuTriggerFor]="notificationsMenu">
    <i class="fa fa-rss bell-icon" aria-hidden="true"></i>
    <span class="badge" *ngIf="unreadMessagesCount$ | async as unreadMessagesCount">{{unreadMessagesCount}}</span>
  </a>

  <mat-menu #notificationsMenu="matMenu" class="tc-notifications-menu">
    <ng-template matMenuContent>
      <div class="notification-heading">
        <div class="title">Notifications</div>
        <a class="view-all-link pull-right" href="javascript:;" (click)="$event.preventDefault(); viewAllFeeds()">View all <i
          class="fa fa-arrow-circle-o-right"></i></a>
      </div>
      <div class="notifications-wrapper">
        <div class="notification-item" *ngFor="let message of unreadMessages$ | async | slice:0:10; trackBy:getMessageId">
          <div class="item-title">{{message.subject}} · {{message.created | epochTimeAgo}}</div>
          <a class="content item-info" href="javascript:;" (click)="showMessageItem(message)">
            {{message.body_txt | slice:0:150}}<span *ngIf="message.body_txt.length > 150">...</span>
          </a>
        </div>

        <div *ngIf="!(unreadMessagesCount$ | async)">
          <div class="notification-item">
            <p class="item-title text-gray">No unread message</p>
          </div>
        </div>
      </div>
      <div class="notification-footer clearfix">
        <span></span>
        <a href="javascript:;" class="view-all-link pull-right" *ngIf="unreadMessagesCount$"
            (click)="$event.preventDefault(); markAllAsRead()">
          <i class="fa fa-check"></i> Mark All Read
        </a>
      </div>
    </ng-template>
  </mat-menu>
</div>
