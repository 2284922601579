import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ModalModule } from "src/shared/modal";
import { CompanyFormComponent } from "./company-form.component";
import { CompanyFormService } from "./company-form.service";
import { CompanyAddressesTabComponent } from "./tabs/company-addresses-tab.component";
import { CompanyBankingTabComponent } from "./tabs/company-banking-tab.component";
import { CompanyContactsTabComponent } from "./tabs/company-contacts-tab.component";
import { CompanyCreditTabComponent } from "./tabs/company-credit-tab.component";
import { CompanyDetailsTabComponent } from "./tabs/company-details-tab.component";
import { CompanyDistributionTabComponent } from "./tabs/company-distribution-tab.component";
import { CompanyLocationsTabComponent } from "./tabs/company-locations-tab.component";
import { CompanyNotesTabComponent } from "./tabs/company-notes-tab.component";
import { CompanyPricingTabComponent } from "./tabs/company-pricing-tab.component";
import { CompanyProfileTabComponent } from "./tabs/company-profile-tab.component";

@NgModule({
  declarations: [CompanyFormComponent],
  providers: [CompanyFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatProgressBarModule,
    MatTabsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ModalModule,
    CompanyAddressesTabComponent,
    CompanyBankingTabComponent,
    CompanyContactsTabComponent,
    CompanyCreditTabComponent,
    CompanyDetailsTabComponent,
    CompanyDistributionTabComponent,
    CompanyLocationsTabComponent,
    CompanyNotesTabComponent,
    CompanyPricingTabComponent,
    CompanyProfileTabComponent,
  ]
})
export class CompanyFormModule {}
