import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { CostsListModule } from 'src/components/costs-list/costs-list.module'
import { InvoiceViewFormModule } from 'src/components/invoices/invoice-view-form/invoice-view-form.module'
import { InvoicesListModule } from 'src/components/invoices/invoices-list'
import { DealProductsListModule } from 'src/pages/admin/logistics/shipping-details-page/deal-products-list/deal-products-list.module'
import { DealTotalsModule } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/deal-totals/deal-totals.module'
import { ShippingSummaryModule } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/shipping-summary/shipping-summary.module'
import { TradingSummaryModule } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/trading-summary/trading-summary.module'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { LocksModule } from 'src/shared/lock/locks.module'
import { PipesModule } from 'src/shared/pipes/pipes.module'
import { BrokerageDatesFormModule } from '../../../brokerage-dates-form/brokerage-dates-form.module'
import { DealFinanceOverlayComponent } from './deal-finance-overlay.component'
import { DealFinanceOverlayService } from './deal-finance-overlay.service'

@NgModule({
  declarations: [DealFinanceOverlayComponent],
  providers: [DealFinanceOverlayService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    DealProductsListModule,
    PipesModule,
    InvoicesListModule,
    CostsListModule,
    DealTotalsModule,
    TradingSummaryModule,
    ShippingSummaryModule,
    LocksModule,
    BrokerageDatesFormModule,
    FlexLayoutModule,
    DealViewIncompleteComponent,
    InvoiceViewFormModule,
  ],
})
export class DealFinanceOverlayModule {}
