import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { HybridRouter } from './angularjs/hybrid-router.service'
import { serializeUrl } from './app-routing.module'
import { SwUpdate } from '@angular/service-worker'
import { ToasterService } from 'src/shared/toaster/toaster.service'

@Component({
  selector: 'tc-root',
  template: `
    <ngx-loading-bar></ngx-loading-bar>
    <tc-header></tc-header>
    <router-outlet class="tc-angular-page"></router-outlet>
    <div ui-view ng-cloak class="ng1-router-outlet"></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [`
    body:not(.tc-login-layout) tc-login,
    .tc-login-layout tc-header,
    .ng1-route-inactive .ng1-router-outlet,
    .ng1-route-active > .ng-star-inserted {
      display: none !important;
    }
  `],
})
export class AppComponent extends OnDestroyMixin implements OnInit {
  @HostBinding('class.ng1-route-active')
  ngRouteActive = false

  @HostBinding('class.ng1-route-inactive')
  ngRouteInactive = false

  loginLayout$ = this.router.urlChanged$.pipe(
    map(x => serializeUrl(x.url) === 'login' || serializeUrl(x.url) === 'forgot-password' || serializeUrl(x.url) === 'reset-password'),
    distinctUntilChanged())

  constructor(private router: HybridRouter, private authService: AuthApiService, private swUpdate: SwUpdate, private toaster: ToasterService) {
    super()
    this.authService.setRealUser()
    this.loginLayout$.pipe(untilComponentDestroyed(this)).subscribe(loginLayout => {
      if (loginLayout) document.body.classList.add('tc-login-layout')
      else document.body.classList.remove('tc-login-layout')
    })
    this.router.angularChanged$.pipe(untilComponentDestroyed(this)).subscribe(ng1 => {
      this.ngRouteActive = ng1
      this.ngRouteInactive = !ng1
    })
  }

  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      this.subscribeToSwUpdates();
    }
  }

  checkForSwUpdates() {
    this.swUpdate.checkForUpdate().then(() => {
      console.log(`[SwUpdate]: Looking for updates`);
    });
  }

  subscribeToSwUpdates() {
    this.swUpdate.versionUpdates.subscribe(event => {
      console.log('SwEvent:', event)
      if (event.type === 'VERSION_READY') {
        console.log('[SwUpdate]: Current version is', event.currentVersion);
        console.log('[SwUpdate]: Available version is', event.latestVersion);
        this.toaster.pop({
          type: 'warning',
          isPersistent: true,
          html: '<div style="display: flex; justify-content: space-between; align-items: center;"><div>New Version Available</div><button style="color: white; background: none; border: none; cursor: pointer; font-weight: bold;" onclick="window.location.reload();">Update</button></div>'
        })
      }
    });
  }
}
