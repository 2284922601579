import { NgModule } from '@angular/core';
import { IdleTimeoutService } from './idle-timeout.service';

@NgModule({
  providers: [IdleTimeoutService],
})
export class IdleTimeoutModule {
  constructor (private idleTimeoutService: IdleTimeoutService) {
    this.idleTimeoutService.init()
  }
}
