import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { SegmentFormModule } from 'src/components/segment-form/segment-form.module'
import { SegmentsListModule } from 'src/components/segments-list/segments-list.module'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { FinanceIssuesModule } from 'src/shared/finance-issues/finance-issues.module'
import { LocksModule } from 'src/shared/lock/locks.module'
import { ShipmentDetailsOverlayComponent } from './shipment-details-overlay.component'
import { ShipmentDetailsOverlayService } from './shipment-details-overlay.service'

@NgModule({
  providers: [ShipmentDetailsOverlayService],
  declarations: [ShipmentDetailsOverlayComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    FlexLayoutModule,
    SegmentsListModule,
    SegmentFormModule,
    LocksModule,
    FinanceIssuesModule,
    DealViewIncompleteComponent,
  ],
})
export class ShipmentDetailsOverlayModule {}
