import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { Store, select } from '@ngrx/store'
import { range } from 'lodash-es'
import { selectAllMeasures } from 'src/app/store/measures'
import { selectAllPackageTypes } from 'src/app/store/package-types'
import { selectAllWeightTypes } from 'src/app/store/weight-types'
import { selectAllWrappingTypes } from 'src/app/store/wrapping-types'
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import { MatchedOfferFormGroup } from '../matched-offer-form.service'

@Component({
  selector: 'tc-matched-offer-specs',
  templateUrl: './matched-offer-specs.component.html',
  styleUrl: './matched-offer-specs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SelectSearchModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveAsteriskModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatAutocompleteModule,
  ]
})
export class MatchedOfferSpecsComponent {
  constructor(private readonly store: Store) {}
  protected readonly weights = range(5000, 26501, 500)
  protected readonly packageTypes$ = this.store.pipe(select(selectAllPackageTypes), waitNotEmpty())
  protected readonly weightTypes$ = this.store.pipe(select(selectAllWeightTypes), waitNotEmpty())
  protected readonly wrappings$ = this.store.pipe(select(selectAllWrappingTypes), waitNotEmpty())
  protected readonly measures$ = this.store.pipe(select(selectAllMeasures), waitNotEmpty())
  @Input() moForm: MatchedOfferFormGroup
}
