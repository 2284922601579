import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { ModalModule } from 'src/shared/modal'
import { MacropointTrackDurationDialogComponent } from './macropoint-track-duration-dialog.component'
import { MacropointTrackDurationDialogService } from './macropoint-track-duration-dialog.service'
import { ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatCheckboxModule } from '@angular/material/checkbox'


@NgModule({
  declarations: [
    MacropointTrackDurationDialogComponent,
  ],
  providers: [MacropointTrackDurationDialogService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressBarModule,
    ModalModule,
  ],
})
export class MacropointTrackDurationDialogModule { }
