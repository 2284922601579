import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { DealDetailsModule } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-details/deal-details.module'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { FinanceIssuesModule } from 'src/shared/finance-issues/finance-issues.module'
import { LocksModule } from 'src/shared/lock/locks.module'
import { ModalModule } from 'src/shared/modal'
import { DealDetailsOverlayComponent } from './deal-details-overlay.component'
import { DealDetailsOverlayService } from './deal-details-overlay.service'

@NgModule({
  declarations: [DealDetailsOverlayComponent],
  providers: [DealDetailsOverlayService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    DealDetailsModule,
    ModalModule,
    LocksModule,
    MatTooltipModule,
    FinanceIssuesModule,
    DealViewIncompleteComponent,
  ],
})
export class DealDetailsOverlayModule { }
