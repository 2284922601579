import { Injectable } from "@angular/core";
import { ModalService } from "src/shared/modal";
import { PasswordFormComponent } from "./password-form.component";

@Injectable()
export class PasswordFormService {
  constructor(private readonly modal: ModalService) { }

  showChangePassword() {
    return this.modal.openDialog<PasswordFormComponent, void, void>(PasswordFormComponent).toPromise()
  }
}
