import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { ModalModule } from 'src/shared/modal'
import { DistributionListOverlayComponent } from './distribution-list-overlay.component'
import { DistributionListOverlayService } from './distribution-list-overlay.service';
import { DistributionListComponent } from './distribution-list/distribution-list.component'
import { SelectSearchModule } from 'src/components/select-search/select-search.module'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { ChipsInputModule } from 'src/components/chips-input/chips-input.module'

@NgModule({
  declarations: [DistributionListOverlayComponent, DistributionListComponent],
  providers: [DistributionListOverlayService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ModalModule,
    SelectSearchModule,
    MatTooltipModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ChipsInputModule
  ],
})

export class DistributionListOverlayModule { }
