import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DateRangePickerModule } from "src/components/date-range-select/date-range-picker.module";
import { ToasterModule } from "src/shared/toaster/toaster.module";
import { ExcelModule } from "../excel.module";
import { CreditsExportService } from "./credits-export.service";

@NgModule({
  providers: [CreditsExportService],
  imports: [
    CommonModule,
    DateRangePickerModule,
    ExcelModule,
    ToasterModule,
  ],
})
export class CreditsExportModule {}
