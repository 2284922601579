import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'tc-badge-invalid-2',
  template: '{{label}}',
  standalone: true,
  styles: `:host.tc-hidden { display: none }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeInvalidComponent {
  protected label: string
  @Input() set errors(errors: boolean | any[]) {
    if (Array.isArray(errors)) {
      this.label = errors.length.toString() || ''
      this.hidden = !errors.length
    } else if (typeof errors === 'boolean') {
      this.label = errors ? '!' : ''
      this.hidden = !!errors
    }
  }
  @HostBinding('class') className = 'badge badge-invalid'
  @HostBinding('class.tc-hidden') hidden = true
}
